import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./home/home.component";
import {ContactusComponent} from './contactus/contactus.component';
import {AppsComponent} from './apps/apps.component';
import { EshopComponent } from './eshop/eshop.component';
import { InTheShopComponent } from './intheshop/intheshop.component';
import { FoodComponent } from './food/food.component';
import { MobileComponent } from './mobile/mobile.component';
import { PolicyComponent } from './policy/policy.component';
import { FinancialsComponent } from './financials/financials.component';
import { BookingSystemComponent } from './booking-system/booking-system.component';
import { FoodMobileComponent } from './food-mobile/food-mobile.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },  
  { path: 'contactus', component: ContactusComponent },
  { path: 'apps', component: AppsComponent },
  { path: 'eshop', component: EshopComponent },
  { path: 'intheshop', component: InTheShopComponent },
  { path: 'food', component: FoodComponent },
  { path: 'food-mobile', component: FoodMobileComponent },
  { path: 'mobile', component: MobileComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'financials', component: FinancialsComponent },
  { path: 'booking-system', component: BookingSystemComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
